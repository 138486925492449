<template lang="pug">
	v-row.pt-10.mt-6.flex-column.justify-center.setting
		v-overlay(:value="show" opacity='0.8' z-index='6')
				v-progress-circular(indeterminate color="primary")
		v-window(v-model="step")
			v-window-item(:value="1")
				v-row.d-none.d-lg-block.d-md-block.d-sm-block.my-8
					v-col.d-flex.justify-center {{$t('setting.userSetting')}}
				//- v-row.px-lg-9.px-sm-7.px-xs-0.setting-Wrap
				//- 	v-col(cols='12')
				//- 		i.material-icons.font-vertical-tb.pr-1 favorite
				//- 		span {{$t('setting.preference')}}
				//- 	v-col.lighter.d-flex.justify-space-between.setting-col.align-center.sec-font-color(cols='12' @click='toBioSetting')
				//- 		span.pl-7 {{$t('setting.biometric')}}
				//- 		i.material-icons.font-vertical-tb navigate_next
				v-row.px-lg-9.mt-3.px-sm-7.px-xs-0.setting-Wrap
					v-col(cols='12')
						i.material-icons.font-vertical-tb.pr-1 person_pin
						span {{$t('setting.personal')}}
					v-col.lighter.d-flex.align-center.justify-space-between.setting-col.sec-font-color(cols='12' @click='openChangePWDialog')
						span.pl-7 {{$t('admin.changePassword')}}
						i.material-icons.font-vertical-tb navigate_next
				v-row.px-lg-9.mt-3.px-sm-7.px-xs-0.setting-Wrap
					v-col(cols='12')
						i.material-icons.font-vertical-tb.pr-1 language
						span {{$t('setting.language')}}
					v-col.setting-col.align-center(cols='12')
						v-select.pl-7.cars-select(v-model='language' :items='languages' :label="$t('common.language')" :value='languages.value' item-text='label' @change='onChange(language)' color='secondary' item-color='#fff' 
						solo single-line background-color='rgba(255, 255, 255, 0.1)' dense style='max-width: 400px;')
							template(v-slot:selection='{ item, index }')
								span(v-if='index === 0')
									span {{ item.label }}
				v-row.px-lg-9.mt-3.px-sm-7.px-xs-0.setting-Wrap
					v-col(cols='12')
						i.material-icons.font-vertical-tb.pr-1 verified_user
						span One Time Password
					v-col.align-center(cols='2')
						v-switch.pl-7(cols='6' v-model='otpEnable' @click='genQR' :label="$t('admin.enable')" color='amber lighten-1')
					v-col(v-if='showQR' cols='4')
						v-text-field(v-model='otpToken' :label="$t('common.enterOTP')" clearable)
					v-col(v-if='showQR' cols='6')
						v-btn.date-btn.defult-btn-color(@click='verifyToken' large) {{$t('admin.verify')}}
				v-row.px-lg-9.mt-3.px-sm-7.px-xs-0.setting-Wrap
					v-col(v-if='showQR' cols='2')
						canvas(id='container') {{ IGU }}
					v-col(v-if='showQR' cols='10')
						span {{$t('admin.step1')}}
						br
						span {{$t('admin.step2')}}
						br
						span {{$t('admin.step3')}}
						br
						span {{$t('admin.step4')}}
				v-snackbar.ma-2(v-model='otpOK' :value='otpOK' :timeout='timeout' centered color='green')
					v-icon(left) check_circle
					span {{successAlertMsg}}
				v-snackbar.ma-2(v-model='otpNOK' :value='otpNOK' :timeout='timeout' centered color='red')
					v-icon(left) cancel
					span {{errorAlertMsg}}
				//- v-row.px-lg-9.mt-3.px-sm-7.px-xs-0.setting-Wrap
				//- 	v-col(cols='12')
				//- 		i.material-icons.font-vertical-tb.pr-1 attach_money
				//- 		span {{$t('setting.currency')}}
				//- 	v-col.setting-col.align-center(cols='12')
				//- 		v-select.pl-7.cars-select(v-model='currency' :items='rateList' :label="$t('common.curencyList')" :value='rateList.value' item-text='label' @change='onChangeCurrency(currency)' color='secondary' item-color='#fff' 
				//- 		solo single-line background-color='rgba(255, 255, 255, 0.1)' dense style='max-width: 400px;')
				//- 			template(v-slot:selection='{ item, index }')
				//- 				span(v-if='index === 0')
				//- 					span {{ item.label }}
				v-row.px-lg-9.px-sm-7.px-xs-0.setting-Wrap-center
					v-col(cols='12')
						span {{$t('setting.apiVersion')}}: {{version}}
			v-window-item(:value="2")
				v-row
					v-col.d-flex.align-center.px-0
						v-btn.prev(rounded='' @click='step = 1')
							i.material-icons.font-vertical-tb.pa-2 navigate_before
							span {{$t('setting.biometric')}}
				v-row
					v-col.d-flex.justify-space-between.setting-col.align-center.sec-font-color(cols='12')
						span.pl-10 {{$t('setting.quickLogin')}}
						v-switch(v-model='biometric' :ripple='false' :disabled='biometricDisable')
		v-dialog(v-model='showPW' persistent width='320px' overlay-opacity='0.9')
			CheckPassword(v-if='showPW' @emitSettingBio='settingBio' @emitClosePWDialog='closePWDialog')
		v-dialog(v-model='modal' persistent width='320px' overlay-opacity='0.9')
			v-card.filter-wrap
				v-card-title
					v-row
						v-col.d-flex.justify-center {{$t('admin.changePassword')}}
				v-card-text
					v-form(ref='form' v-model='valid')
						v-text-field(v-model='oldPassword' type='password' :label="$t('common.oldPassword')" :rules='[rules.required, rules.max, rules.min]' :counter='counter' clearable)
						v-text-field(v-model='newPasswordFirst' type='password' :label="$t('common.newPasswordFirst')" :rules='[rules.required, rules.max, rules.min]' :counter='counter' clearable)
						v-text-field(v-model='newPasswordSecond' type='password' :label="$t('common.newPasswordSecond')" :rules='[rules.required, rules.max, rules.min]' :counter='counter' clearable)
				v-card-actions
					v-row
						v-col.d-flex.justify-space-between
							v-btn.date-btn.mr-3(color="grey darken-2" @click='close' large) {{$t('admin.cancel')}}
							v-btn.date-btn.defult-btn-color(@click='save' large) {{$t('admin.save')}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { checkPassword, verifyPassword } from '@/api/Admin';
import { getApiServerVersion } from '@/api/Server';
import { closeOTP, openOTP, verifyOTP } from '@/api/Config';
import statusCode from '@/assets/constant/statusCode';
import CheckPassword from '@/components/CheckPassword';
import QRCode from 'qrcode';

export default {
	components: {
		CheckPassword
	},
	data() {
		return {
			valid: true,
			show: false,
			biometric: '',
			deviceBioEnable: '',
			biometricDisable: true,
			version: '',
			// biometricDisable: false,
			showPW: false,
			modal: false,
			timeout: 2000,
			otpEnable: false,
			showQR: false,
			IGU: '',
			otpToken: '',
			otpOK: false,
			otpNOK: false,
			errorAlertMsg: 'I am an error alert.',
			successAlertMsg: 'I am a success alert.',
			step: 1,
			password: '',
			currency: 'THB',
			rateList: [], // currencyList
			language: 'en',
			languages: [
				{
					value: 'en',
					label: 'English'
				},
				{
					value: 'tw',
					label: '中文'
				}
			],
			oldPassword: '',
			newPasswordFirst: '',
			newPasswordSecond: '',
			counter: 30,
			rules: {
				required: value => !!value || this.$t('error.thisFieldIsRequired'),
				max: v => (!!v && v.length <= 30) || this.$t('error.max30'),
				min: v => (!!v && v.length > 3) || this.$t('error.min4')
			}
		};
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		onChange(value) {
			if (localStorage.getItem('language') !== value) {
				localStorage.setItem('language', value);
				window.location.reload();
			}
		},
		onChangeCurrency(value) {
			if (localStorage.getItem('currency') !== value) {
				localStorage.setItem('currency', value);
			}
		},
		getCurrencySetting() {
			if (localStorage.getItem('currency')) {
				this.currency = localStorage.getItem('currency');
			} else {
				localStorage.setItem('currency', this.currency);
			}
		},
		getLanguageSetting() {
			if (localStorage.getItem('language')) {
				this.language = localStorage.getItem('language');
			} else {
				localStorage.setItem('language', this.language);
			}
		},
		async genQR() {
			const reqData = {
				operatorID: this.getOperator.operatorID,
			}
			if(this.otpEnable) {
				this.showQR = true;
				const res = await openOTP(reqData);
				if (res.status === statusCode.STATUS_OK) {
					this.secret = res.result.secret;
					const link = res.result.link;
					QRCode.toCanvas(document.getElementById('container'), link, function (error) {
						if (error) console.error(error);
						// console.log('success!');
					})
				}
			} else {
				const reqData = {
					operatorAC: this.getOperator.operatorName,
				}
				this.showQR = false;
				const res = await closeOTP(reqData);
				if (res.status === statusCode.STATUS_OK) {
					if(res.result === 'success') {
						this.getOperator.otpSecret = false;
						this.successAlertMsg = this.$t('admin.otpClose');
						this.otpOK = true;
						this.otpNOK = false;
					} else {
						this.errorAlertMsg = this.$t('admin.otpCFail');
						this.otpOK = false;
						this.otpNOK = true;
					}
				}
			}
		},
		async verifyToken() {
			const reqData = {
				operatorAC: this.getOperator.operatorName,
				secret: this.secret,
				token: this.otpToken
			}
			const res = await verifyOTP(reqData);
			if (res.status === statusCode.STATUS_OK) {
				if(res.result === 'success') {
					this.getOperator.otpSecret = true;
					this.successAlertMsg = this.$t('admin.otpSuccess');
					this.otpOK = true;
					this.otpNOK = false;
				} else {
					this.errorAlertMsg = this.$t('admin.otpFail');
					this.otpOK = false;
					this.otpNOK = true;
				}
			}
		},
		handleLogut() {
			window.sessionStorage.clear();
			window.location.reload();
		},
		async save() {
			this.show = true;
			if (this.oldPassword && this.newPasswordFirst && this.newPasswordSecond) {
				if (
					this.newPasswordFirst.length >= 4 &&
					this.newPasswordSecond.length >= 4 &&
					this.newPasswordFirst === this.newPasswordSecond
				) {
					const reqData = {
						operatorAC: this.getOperator.operatorName,
						oldPW: this.oldPassword,
						operatorPW: this.newPasswordSecond
					};
					const res = await checkPassword(reqData);
					if (res.status === statusCode.STATUS_OK) {
						let updatedPSW = this.$t('success.updatedPSW');
						this.successDialogStatus(updatedPSW);
						this.updateBio(this.newPasswordSecond);
						this.close();
					} else if (res.status === statusCode.STATUS_INCORRECT_PASSWORD) {
						let incorrectPSW = this.$t('error.incorrectPSW');
						this.errorDialogStatus(incorrectPSW);
						this.close();
					}
				} else {
					let enterNotMatch = this.$t('error.enterNotMatch');
					this.errorDialogStatus(enterNotMatch);
					this.close();
				}
			} else {
				this.$refs.form.validate();
			}
			this.show = false;
		},
		updateBio(data) {
			const reqData = {
				operatorAC: this.getOperator.operatorName,
				operatorPW: data
			};
			if (this.biometric) {
				window.delInfoResult = status => {
					if (status === '1') {
						window.postInfoResult = status => {
							if (status === '1') {
								this.handleLogut();
							}
						};
						window.location = `native://app/v2/storage?action=POST&key=loginInfo&value=${JSON.stringify(
							reqData
						)}&callback=postInfoResult`;
					}
				};
				window.location = 'native://app/v2/storage?action=DELETE&key=loginInfo&callback=delInfoResult';
			}
		},
		close() {
			this.$refs.form.reset();
			this.modal = false;
		},
		openChangePWDialog() {
			this.modal = true;
		},
		toBioSetting() {
			this.step = 2;
		},
		closePWDialog() {
			this.hasBiometric();
			this.password = '';
			this.showPW = false;
		},
		async hasBiometric() {
			await this.deviceBio();
			if (this.deviceBioEnable) {
				// 支援生物電視
				window.getInfoResult = status => {
					if (status !== '') {
						// 已有keychain
						// this.biometric = true;
						this.biometric = false;
						this.biometricDisable = true;
					} else {
						// 沒有keychain
						this.biometric = false;
						this.biometricDisable = true;
					}
				};
				window.location = 'native://app/v2/storage?action=GET&key=loginInfo&callback=getInfoResult';
			} else {
				// 不支援生物辨識
				this.biometric = false;
				this.biometricDisable = true;
			}
		},
		async settingBio(data) {
			this.show = true;
			if (data.length >= 4) {
				const reqData = {
					operatorAC: this.getOperator.operatorName,
					operatorPW: data
				};
				const res = await verifyPassword(reqData);
				if (res.status === statusCode.STATUS_OK) {
					window.postInfoResult = status => {
						if (status === '1') {
							let openQuickLoginSuccess = this.$t('success.openQuickLoginSuccess');
							this.successDialogStatus(openQuickLoginSuccess);
							this.closePWDialog();
						}
					};
					window.location = `native://app/v2/storage?action=POST&key=loginInfo&value=${JSON.stringify(
						reqData
					)}&callback=postInfoResult`;
				} else if (res.status === statusCode.STATUS_INCORRECT_PASSWORD) {
					let userPasswordWrong = this.$t('error.userPasswordWrong');
					this.errorDialogStatus(userPasswordWrong);
				} else {
					this.errorDialogStatus('api error');
				}
			}
			this.show = false;
		},
		deviceBio() {
			let userAgent = navigator.userAgent;
			this.deviceBioEnable = userAgent.includes('TokenLogin');
		},
		successDialogStatus(notifyMsg) {
			let successTitle = this.$t('success.successTitle');
			const successMsg = {
				statusMessage: successTitle,
				statusSrc: require('@/assets/image/success_icon.png'),
				statusText: notifyMsg
			};
			let stringSuccessMsg = JSON.stringify(successMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringSuccessMsg);
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		async getApiInfo(){
			try {
				const res = await getApiServerVersion();
				if (res.status === statusCode.STATUS_OK) {
					this.version = res.result.version;
				}
			} catch (e) {
				// logger('error', e.message);
			}	
		}
	},
	computed: {
		...mapGetters(['getCurrencyList', 'getOperator', 'getDialogShow', 'getStatusDialog'])
	},
	watch: {
		biometric(val) {
			if (this.deviceBioEnable) {
				window.getInfoResult = status => {
					if (status === '' && val) {
						this.showPW = true;
					} else if (!val && status !== '') {
						window.delInfoResult = status => {
							if (status === '1') {
								let closeQuickLoginSuccess = this.$t('success.closeQuickLoginSuccess');
								this.successDialogStatus(closeQuickLoginSuccess);
								this.closePWDialog();
							}
						};
						window.location = 'native://app/v2/storage?action=DELETE&key=loginInfo&callback=delInfoResult';
					}
				};
				window.location = 'native://app/v2/storage?action=GET&key=loginInfo&callback=getInfoResult';
			}
		}
	},
	mounted() {
		this.getCurrencySetting();
		this.getLanguageSetting();
		this.hasBiometric();
		this.getApiInfo();
	},
	created(){
		if (this.getOperator.otpSecret) {
			this.otpEnable = true;
			this.showQR = false;
		}
		// for(let i of this.getCurrencyList){
		// 	this.rateList.push({
		// 		value: i,
		// 		label: i
		// 	});
		// }
	}
};
</script>
