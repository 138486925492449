<template lang="pug">
    v-row.filter-wrap
        v-row
            v-form(ref="pwForm" v-model="valid")
                v-col.d-flex.justify-space-between.align-center.sec-font-color(cols='12')
                    v-text-field(v-model='password' type='password' :label="$t('admin.password')" :rules='[rules.required, rules.max, rules.min]' clearable)
        v-row
            v-col.d-flex.justify-space-between(cols='12')
                v-btn.date-btn.mr-3(color="grey darken-2" @click='closePWDialog' large) {{$t('admin.cancel')}}
                v-btn.date-btn.defult-btn-color(@click='settingBio' large) {{$t('common.ok')}}
</template>
<script>
export default {
	data() {
		return {
			password: '',
			valid: '',
			rules: {
				required: value => !!value || this.$t('error.thisFieldIsRequired'),
				max: v => v.length <= 30 || this.$t('error.max30'),
				min: v => v.length > 3 || this.$t('error.min4'),
			}
		};
	},
	methods: {
		closePWDialog() {
			this.$emit('emitClosePWDialog');
		},
		settingBio() {
			this.$refs.pwForm.validate();
			if(this.valid){
				this.$emit('emitSettingBio', this.password);
			}
		},
	}
};
</script>