<template lang="pug">
.pt-10.mt-6
	v-row(v-if='last5.length !== 0')
		v-col.justify-center.d-flex(cols='12')
			span.sec-font-color {{ $t("common.recentInquiry") }}
	swiper.swiper-wrap(:options='swiperOption', ref='mySwiper', v-if='last5.length !== 0 && !swiperType')
		swiper-slide.mx-2.index-slide-card.main-bg-img(v-for='(item, index) in recordArr', :key='index')
			v-row.fill-height
				v-col.justify-center.flex-column.d-flex.align-center(style='transition: 0.5s', @click='goPath(index, item.path)')
					i.icon-large.pb-3.material-icons {{ item.icon }}
					span.font-large.text-center {{ item.name }}
					span.sec-font-color.small-font {{ item.group }}
	v-row(v-if='last5.length !== 0 && swiperType')
		v-col.pa-0(cols='12')
			v-sheet.mx-auto.sheetWrap
				v-slide-group.justify-center(v-model='model', align='center', justify='center', center-active, mandatory)
					v-slide-item.index-slide-card.align-center(v-slot:default='{ active, toggle }', v-for='(item, index) in recordArr', :key='index')
						v-card.mx-2.main-bg-img(@click='toggle', :ripple='false', :class='active ? "card-active" : "card-active-not"')
							v-row.fill-height(align='center', justify='center')
								v-scale-transition
									v-col.justify-center.flex-column.d-flex(@click='active ? goMenuPath(item.path) : ""')
										i.icon-large.pb-3.material-icons {{ item.icon }}
										span.font-large {{ item.name }}
										span.sec-font-color.small-font {{ item.group }}
	v-row.pt-6
		v-col(cols='12', lg='6', v-for='(item, index) in menus', :key='index')
			v-card.index-card
				v-col.justify-center.text-center(v-if='item.items', :key='item.title')
					h3.sec-font-color.pb-5 {{ item.title }}
					v-row.justify-center
						v-col.justify-start.d-flex.flex-column.align-center(
							v-for='subItem in item.items',
							:key='subItem.name',
							cols='4',
							@click='goMenuPath(subItem.path)'
						)
							span.circleIcon.mb-3 {{ handleIcon(subItem.path) }}
							router-link.sec-font-color.small-font(:to='subItem.path', style='text-decoration: none; color: white') {{ subItem.title }}
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			showSwiper: true,
			windowsWidth: '',
			last5: [],
			menus: [],
			model: 0,
			recordArr: [],
			swiperOption: {
				slidesPerView: 'auto',
				centeredSlides: true,
				speed: 500,
			},
		};
	},
	created() {
		this.createRouter();
	},
	mounted() {
		this.getStorage();
		this.findRouterData();
		this.handleSwiper();
	},
	methods: {
		getStorage() {
			if (JSON.parse(localStorage.getItem('lastSeachPage'))) {
				this.last5 = JSON.parse(localStorage.getItem('lastSeachPage'));
			}
		},
		goPath(idx, data) {
			if (idx === this.swiper.activeIndex) {
				this.$router.push({ path: data });
			}
		},
		goMenuPath(data) {
			this.$router.push({ path: data });
		},
		goSetting() {
			this.$router.push({ path: '/setting' });
		},
		createRouter() {
			const Menu = [];
			// eslint-disable-next-line no-undef
			for (const i of this.permission_routers) {
				// if (i.path.indexOf('datamonitor') !== -1 || i.path.indexOf('general') !== -1 || i.path.indexOf('customer') !== -1) {
				if (
					i.path.indexOf('ocms') !== -1 ||
					i.path.indexOf('provider') !== -1 ||
					i.path.indexOf('onlineGeneral') !== -1 ||
					i.path.indexOf('customer') !== -1 ||
					i.path.indexOf('house') !== -1
				) {
					const tmpObj = {};
					tmpObj.title = i.meta.title;
					tmpObj.group = i.meta.group;
					tmpObj.icon = i.meta.icon;
					if (i.children) {
						tmpObj.items = [];
						for (const j of i.children) {
							tmpObj.items.push({
								name: j.name,
								title: j.meta.title,
								path: j.path,
							});
						}
					}
					Menu.push(tmpObj);
				}
			}
			this.menus = Menu;
		},
		handleIcon(data) {
			if (data.indexOf('dashboard') !== -1) return 'D';
			if (data.indexOf('date') !== -1) return 'D';
			if (data.indexOf('trend') !== -1) return 'T';
			if (data.indexOf('game') !== -1) return 'G';
			if (data.indexOf('maintenance') !== -1) return 'A';
			if (data.indexOf('bankAccountung') !== -1) return 'BA';
			if (data.indexOf('Banking') !== -1) return 'SB';
			if (data.indexOf('Customer') !== -1) return 'SC';
			if (data.indexOf('channel') !== -1) return 'C';
			if (data.indexOf('Activity') !== -1) return 'MA';
			if (data.indexOf('Deposit') !== -1) return 'MD';
			if (data.indexOf('periodicalSite') !== -1) return 'PS';
			if (data.indexOf('periodicalGame') !== -1) return 'PG';
			if (data.indexOf('MachineType') !== -1) return 'MT';
			if (data.indexOf('MachineEach') !== -1) return 'ME';
			if (data.indexOf('single') !== -1) return 'S';
			if (data.indexOf('entire') !== -1) return 'PR';
			if (data.indexOf('promoCompare') !== -1) return 'PC';
			if (data.indexOf('lookup') !== -1) return 'L';
			if (data.indexOf('agentCompare') !== -1) return 'AC';
			if (data.indexOf('siteRecap') !== -1) return 'SR';
			if (data.indexOf('memberTop') !== -1) return 'TM';
			if (data.indexOf('memberReg') !== -1) return 'NO';
			if (data.indexOf('memberCvt') !== -1) return 'CR';
		},
		findRouterData() {
			for (const i of this.last5) {
				const showRecord = {
					group: '',
					icon: '',
					name: '',
					path: '',
				};
				for (let j of this.menus) {
					const routerObj = _.filter(j.items, { path: i.path });
					if (routerObj.length >= 1) {
						showRecord.path = i.path;
						showRecord.group = j.title;
						showRecord.icon = j.icon;
						showRecord.name = routerObj[0].title;
					}
				}
				this.recordArr.push(showRecord);
			}
		},
		handleSwiper() {
			this.windowsWidth = window.innerWidth;
			if (this.windowsWidth > 1024) {
				this.showSwiper = true;
			} else {
				this.showSwiper = false;
			}
		},
	},
	computed: {
		...mapGetters(['permission_routers']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		swiperType() {
			const vm = this;
			window.addEventListener('resize', function () {
				vm.handleSwiper();
			});

			return this.showSwiper;
		},
	},
};
</script>
